<template>
  <div class="container pb-4">
    <div
      ref="item"
      class="item"
      v-for="(item, i) in list"
      :key="i"
      :style="{'z-index': i + 1}"
    >
      <div
        ref="itemHeader"
        class="header text-secondary-hover py-10"
        @click="toggle(i)"
      >
        <slot name="header" :item="item" :index="i" />
      </div>
      <div
        ref="itemWrapper"
        class="wrapper">
          <div
            ref="itemContent"
            class="content pb-10"
          >
            <slot name="content" :item="item" :index="i" />
          </div>
      </div>
      <div class="line"></div>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap'
import WaitLoader from '@/mixins/wait-loader'

export default {
  name: 'AccordionList',
  mixins: [WaitLoader(1000)],
  props: {
    list: { type: Array }
  },
  async mounted () {
    this.state = []
    await this.$nextTick()
    this.createTl()
  },
  beforeDestroy () {
    this.tl && this.tl.forEach(tl => tl.kill())
  },
  methods: {
    createTl () {
      this.tl = []
      this.$refs.item && this.$refs.item.forEach(item => {
        const els = item.querySelectorAll('.stagger')
        const line = item.querySelectorAll('.line')
        gsap.set(els, { opacity: 0 })
        gsap.set(line, { scaleX: 0 })

        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: item,
            start: 'bottom bottom'
          }
        })
        this.checkLoaderState()
        tl.fromTo(els, { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.3, stagger: 0.1 })
        tl.fromTo(line, { scaleX: 0 }, { scaleX: 1, duration: 0.3 }, '<+=0.2')
        this.tl.push(tl)
      })
    },
    toggle (i) {
      this.state[i] = !this.state[i]
      this.$refs.itemHeader[i].classList.toggle('open', this.state[i])
      gsap.to(this.$refs.itemWrapper[i], {
        duration: 0.3,
        height: this.state[i] ? this.$refs.itemContent[i].getBoundingClientRect().height : 0,
        overwrite: true,
        onComplete: () => {
          this.$refs.itemWrapper[i].style.height = this.state[i] ? 'auto' : 0
        }
      })
    }
  }

}
</script>

<style lang="scss" scoped>
.item {
  position: relative;
}

.header {
  position: relative;

  padding-right: rem(40px);

  cursor: pointer;
  transition: color 0.3s;
}

.wrapper {
  overflow: hidden;
  height: 0;

  will-change: height;
}

.content {
  @screen lg {
    width: 50%;
  }
}

.line {
  position: absolute;
  bottom: 0;
  left: 50%;

  width: 90vw;
  min-width: 100%;
  height: rem(1px);

  background: currentColor;
  opacity: 0.3;
  transform: translateX(-50%);
}
</style>
